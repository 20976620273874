<template>
  <div
    class="my-host-container drawer-detection-container"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <div class="host-main-page">
      <!--
        If we delete selectedHost from:
        <div v-if="loadingPage || !selectedHost"> here
        and <div v-if="!loadingPage || selectedHost"> below
        then after we go from /hosts/... to /active-host
        the page is not in the "loadingPage" state
        because we are changing the ref, but not the component, its already mounted
      -->
      <div v-if="loadingPage || !selectedHost" class="host-spinner-height d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!loadingPage && selectedHost">
        <v-toolbar>
          <v-toolbar-title class="d-flex">
            <span>Host "{{ selectedHost ? selectedHost.name : "" }}"</span>
          </v-toolbar-title>
          <template v-slot:extension>
            <v-tabs v-model="tabControl">
              <v-tabs-slider color="accent"></v-tabs-slider>
              <v-tab
                class="ml-0 item-display"
                v-for="(item, index) in tabItems"
                :key="index"
                @click="changeTab(item, index)"
              >
                {{ item.display }}
              </v-tab>
            </v-tabs>
          </template>

          <HostStatusComponent
            class="host-status ml-2 mt-1 mr-2"
            :p-selected-host="selectedHost"/>
          </v-toolbar>

        <v-tabs-items v-model="tabControl" class="tabs-items-background">
          <v-tab-item>
            <v-container fluid>
              <HostStats class="mb-3" />
              <HostInvitationInfo
                class="mb-3"
                v-if="(selectedHost || {}).status === HOST_STATUS_DRAFT"
              />
              <Teams @onMount="teamsMounted = true" :pSelectedHost="selectedHost" />
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid class="host-data ml-0 mr-0">
              <CustomAddEditComponent
                v-if="selectedHost"
                class="host-component"
                ref="hostInfo"
                :pCardTitle="selectedHost ? `Host '${selectedHost.name}'` : ''"
                :pEditedItem="selectedHost"
                :pEditedItemType="HOST_ADD_EDIT_ITEM"
                :pItemParams="HOST_PARAMS"
                :pLoading="loadingInComponents"
                :pDisabledParams="[]"
                @onItemCreatedOrUpdated="updateAndReloadHost($event)"
              />
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid>
              <HostInvitations @onMount="invitationsMounted = true" :pSelectedHost="selectedHost" />
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid>
              <UsersByHost @onMount="usersMounted = true" :pSelectedHost="selectedHost" @update="reloadDataInComponents" />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import {
  dismissAction,
  logAndExtractMessage,
  HOST_ADD_EDIT_ITEM,
  HOST_PARAMS,
  HOST_STATUS_DRAFT, UUID_REGEX,
} from "@/utils/utils";

export default {
  name: "HostPage",
  components: {
    HostInvitations: () => import("./HostInvitations.vue"),
    HostStatusComponent: () => import("../custom-components/HostStatusComponent"),
    HostInvitationInfo: () => import("./HostInvitationInfo"),
    CustomAddEditComponent: () => import("../custom-components/AddEditComponent.vue"),
    UsersByHost: () => import("./UsersByHost.vue"),
    HostStats: () => import("./HostStats.vue"),
    Teams: () => import("./Teams.vue"),
  },

  props: {
    pMainRoute: String,
  },

  data: () => ({
    HOST_ADD_EDIT_ITEM,
    HOST_PARAMS,
    HOST_STATUS_DRAFT,

    tabItems: [
      {
        display: "Dashboard",
        route: "dashboard",
      },
      {
        display: "Host Info",
        route: "host-info",
      },
      {
        display: "Invitations",
        route: "invitations",
      },
      {
        display: "Users",
        route: "users",
      },
    ],

    usersMounted: false,
    invitationsMounted: false,
    teamsMounted: false,

    tabControl: 0,

    loadingPage: false,
    loadingInComponents: false,
  }),

  watch: {
    hostId() {
      this.fetchHost(true);
    },
  },

  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("userModule", ["user"]),
    ...mapState("hostModule", ["selectedHost"]),

    isActiveHostPage() {
      return this.pMainRoute === "/active-host";
    },

    hostId() {
      return this.isActiveHostPage
        ? this.user.activeHostMembership.host.id
        : this.$route.params.id;
    },

    tabIndex() {
      return this.tabItems.findIndex((item) => item.route === this.$route.query.tab) || 0;
    },
  },

  methods: {
    changeTab(tab) {
      if (tab.route !== this.$route.query.tab) {
        this.$router.push({
          path: this.pMainRoute,
          query: { tab: tab.route },
        });
        this.tabControl = this.tabIndex;
      }
    },

    async updateAndReloadHost(host) {
      this.loadingInComponents = true;
      try {
        await this.$store.dispatch("hostModule/addOrSaveHost", host);
        await this.reloadDataInComponents();
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
        this.$refs.hostInfo.removeChanges();
        this.$refs.hostInfo.enableFields();
      }
      this.loadingInComponents = false;
    },

    async reloadDataInComponents() {
      await this.fetchHost(false);

      await Promise.all([
        this.usersMounted && this.$store.dispatch("hostUsersModule/reloadPage"),
        this.teamsMounted && this.$store.dispatch("teamsModule/reloadPage"),
        this.invitationsMounted && this.$store.dispatch("hostInvitationsModule/reloadPage"),
      ]);
    },

    async fetchHost(selectedHostNeedsToBeCleared = true) {
      this.clearDataInComponents(selectedHostNeedsToBeCleared);

      if (UUID_REGEX.test(this.hostId)) {
        try {
          await this.$store.dispatch("hostModule/loadHostStats", this.hostId);
          await this.$store.dispatch("hostModule/getHostById", this.hostId);
        } catch (e) {
          Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          await this.$router.push("/hosts").catch(() => {});
        }
      } else {
        Vue.toasted.error("Incorrect host ID in URL", { ...dismissAction });
        await this.$router.push("/hosts").catch(() => {});
      }

      this.tabControl = this.tabIndex;
    },

    clearDataInComponents(selectedHostNeedsToBeCleared) {
      if (selectedHostNeedsToBeCleared) {
        // If the host is cleared the page will be
        // in the "loadingPage" state (will be view very fast spinner)
        // Its cool when the page loads, but not when we update the host :)

        // And we need to block clearing host when the reloads data
        // in page -> reloadDataInComponents()
        this.updateSelectedHost(undefined);
        this.updateHostStats(undefined);
      }
      this.updateCurrentPageUsers([]);
      this.updateCurrentPageTeams([]);
      this.updateCurrentPageInvitations([]);
    },

    updateSelectedHost(value) {
      this.$store.commit("hostModule/updateSelectedHost", value);
    },

    updateHostStats(value) {
      this.$store.commit("hostModule/updateHostStats", value);
    },

    updateCurrentPageUsers(value) {
      this.$store.commit("hostUsersModule/updateCurrentPageUsers", value);
    },

    updateCurrentPageTeams(value) {
      this.$store.commit("teamsModule/updateCurrentPageTeams", value);
    },

    updateCurrentPageInvitations(value) {
      this.$store.commit("hostInvitationsModule/updateCurrentPageHostInvitations", value);
    },
  },

  async created() {
    this.loadingPage = true;

    if (!this.$route.query.tab) {
      this.$router.push({
        path: this.pMainRoute,
        query: { tab: this.tabItems[0].route },
      });
    }

    this.tabControl = this.tabIndex;
    await this.fetchHost();
    this.loadingPage = false;
  },
};
</script>

<style scoped lang="scss">
.my-host-container {
  .host-spinner-height {
    height: 90vh;
  }

  .host-data {
    width: 100%;
  }

  .item-display {
    display: flex;
    align-items: center;
    text-decoration: none;

    div {
      color: var(--v-textColor-base) !important;
      height: 100%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .my-host-container {
    .host-data {
      width: 40%;
    }
  }
}
</style>
